import { defineMessages } from "react-intl";

export const messages = defineMessages({
  seeError: {
    id: "w9xgN9",
    defaultMessage: "See error log",
    description: "see error log label in notification",
  },
  hideError: {
    id: "s8FlDW",
    defaultMessage: "Hide log",
    description: "hide error log label in notification",
  },
});
