import { createSvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const Warehouses = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.5 5C10.5 4.44772 10.9477 4 11.5 4H13.1429V6.64286C13.1429 7.74743 14.0383 8.64286 15.1429 8.64286H16.8571C17.9617 8.64286 18.8571 7.74743 18.8571 6.64286V4H20.5C21.0523 4 21.5 4.44772 21.5 5V14C21.5 14.5523 21.0523 15 20.5 15H18H14H11.5C10.9477 15 10.5 14.5523 10.5 14V5ZM8.67071 15C8.56015 14.6872 8.5 14.3506 8.5 14V5C8.5 3.34315 9.84314 2 11.5 2H20.5C22.1569 2 23.5 3.34315 23.5 5V14C23.5 14.3506 23.4398 14.6872 23.3293 15H27C28.6569 15 30 16.3431 30 18V27C30 28.6569 28.6569 30 27 30H18C17.2316 30 16.5308 29.7111 16 29.2361C15.4692 29.7111 14.7684 30 14 30H5C3.34315 30 2 28.6569 2 27V18C2 16.3431 3.34315 15 5 15H8.67071ZM12.3571 17V19.6429C12.3571 20.7474 11.4617 21.6429 10.3571 21.6429H8.64286C7.53829 21.6429 6.64286 20.7474 6.64286 19.6429V17H5C4.44772 17 4 17.4477 4 18V27C4 27.5523 4.44772 28 5 28H14C14.5523 28 15 27.5523 15 27V18C15 17.4477 14.5523 17 14 17H12.3571ZM18 17C17.4477 17 17 17.4477 17 18V27C17 27.5523 17.4477 28 18 28H27C27.5523 28 28 27.5523 28 27V18C28 17.4477 27.5523 17 27 17H25.3571V19.6429C25.3571 20.7474 24.4617 21.6429 23.3571 21.6429H21.6429C20.5383 21.6429 19.6429 20.7474 19.6429 19.6429V17H18ZM15.1429 6.64286V4H16.8571V6.64286L15.1429 6.64286ZM8.64286 17V19.6429H10.3571V17H8.64286ZM21.6429 17V19.6429H23.3571V17H21.6429Z"
    fill="currentColor"
  />,
  "Warehouses",
);

export default (props: SvgIconProps) => (
  <Warehouses {...props} viewBox="0 0 32 32" />
);
