import {
  extensionMountPoints,
  mapToMenuItems,
  useExtensions,
} from "@dashboard/apps/hooks/useExtensions";
import { LimitsInfo } from "@dashboard/components/AppLayout/LimitsInfo";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import { Button } from "@dashboard/components/Button";
import { ButtonWithSelect } from "@dashboard/components/ButtonWithSelect";
import CardMenu from "@dashboard/components/CardMenu";
import FilterBar from "@dashboard/components/FilterBar";
import { ListPageLayout } from "@dashboard/components/Layouts";
import { OrderListQuery, RefreshLimitsQuery } from "@dashboard/graphql";
import { sectionNames } from "@dashboard/intl";
import { OrderListUrlSortField } from "@dashboard/orders/urls";
import {
  FilterPageProps,
  PageListProps,
  RelayToFlat,
  SortPage,
} from "@dashboard/types";
import { hasLimits, isLimitReached } from "@dashboard/utils/limits";
import { Card } from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { FormattedMessage, useIntl } from "react-intl";

import OrderLimitReached from "../OrderLimitReached";
import OrderList from "../OrderList";
import {
  createFilterStructure,
  OrderFilterKeys,
  OrderListFilterOpts,
} from "./filters";

export interface OrderListPageProps
  extends PageListProps,
    FilterPageProps<OrderFilterKeys, OrderListFilterOpts>,
    SortPage<OrderListUrlSortField> {
  limits: RefreshLimitsQuery["shop"]["limits"];
  orders: RelayToFlat<OrderListQuery["orders"]>;
  onSettingsOpen: () => void;
  onAdd: () => void;
  csvData: any;
}

const useStyles = makeStyles(
  theme => ({
    settings: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: "OrderListPage" },
);

const OrderListPage: React.FC<OrderListPageProps> = ({
  csvData,
  currentTab,
  initialSearch,
  filterOpts,
  limits,
  tabs,
  onAdd,
  onAll,
  onSearchChange,
  onSettingsOpen,
  onFilterChange,
  onTabChange,
  onTabDelete,
  onTabSave,
  ...listProps
}) => {
  const intl = useIntl();
  const classes = useStyles({});
  const filterStructure = createFilterStructure(intl, filterOpts);
  const limitsReached = isLimitReached(limits, "orders");

  const { ORDER_OVERVIEW_CREATE, ORDER_OVERVIEW_MORE_ACTIONS } = useExtensions(
    extensionMountPoints.ORDER_LIST,
  );
  const extensionMenuItems = mapToMenuItems(ORDER_OVERVIEW_MORE_ACTIONS);
  const extensionCreateButtonItems = mapToMenuItems(ORDER_OVERVIEW_CREATE);

  const getCSVData = (orders: any) => {
    const list: any[] = [];
    if (orders) {
      orders.forEach(order => {
        console.log(order)
        const orderData = { ...order };
        orderData.number = "#" + orderData.number;
        orderData.userEmail = orderData.userEmail;
        orderData.name =
          orderData.billingAddress.firstName +
          " " +
          orderData.billingAddress.lastName;
        orderData.phone = orderData.billingAddress.phone;
        orderData.created = new Date(orderData.created).toLocaleString();
        let products = "";
        // eslint-disable-next-line chai-friendly/no-unused-expressions
        orderData?.lines?.forEach((value, id) => {
          products +=
            id +
            1 +
            ". " +
            value.productName +
            " x" +
            value.quantity +
            " HKD" +
            value.totalPrice.gross.amount;
          if (id < orderData.lines.length) {
            products += " ";
          }
        });
        orderData.products = products;
        orderData.shippingAddress =
          orderData.shippingAddress.streetAddress1 +
          " " +
          orderData.shippingAddress.streetAddress2 +
          " " +
          orderData.shippingAddress.cityArea +
          " " +
          orderData.shippingAddress.city +
          " " +
          orderData.shippingAddress.countryArea +
          " " +
          orderData.shippingAddress.country.country;
        orderData.billingAddress =
          orderData.billingAddress.streetAddress1 +
          " " +
          orderData.billingAddress.streetAddress2 +
          " " +
          orderData.billingAddress.cityArea +
          " " +
          orderData.billingAddress.city +
          " " +
          orderData.billingAddress.countryArea +
          " " +
          orderData.billingAddress.country.country;
        const total = orderData.total.gross.amount.toString();
        orderData.shippingPrice =
          orderData.shippingPrice.gross.amount.toString();
        orderData.voucher = orderData.voucher ? orderData.voucher.code : "";
        orderData.total = "HKD " + total;

        if(order.payments && order.payments?.length > 0 && order.payments[0].metadata) {
          for (let index = 0; index < order.payments[0].metadata.length; index++) {
            const metadata = order.payments[0].metadata[index]
            if(metadata.key === "notes") {
              orderData.notes = metadata.value
            }
          }
        }

        orderData.isSnowbrandMember =
          order.user && order.user?.isActive ? "true" : "false";
        orderData.stripeId =
          order.payments?.length > 0 &&
          order.payments?.[0].transactions.length > 0
            ? order.payments?.[0].transactions?.[0].token
            : "";
        list.push(orderData);
      });
    }
    list.sort((a, b) => (a.number > b.number) ? 1 : -1)

    return list;
  };

  const headers = [
    { label: "number", key: "number" },
    { label: "date", key: "created" },
    { label: "email", key: "userEmail" },
    { label: "customer", key: "name" },
    { label: "products", key: "products" },
    { label: "phone", key: "phone" },
    { label: "billingAddress", key: "billingAddress" },
    { label: "shippingAddress", key: "shippingAddress" },
    { label: "payment", key: "paymentStatus" },
    { label: "fulfillment status", key: "status" },
    { label: "shippingPrice", key: "shippingPrice" },
    { label: "voucher", key: "voucher" },
    { label: "total", key: "total" },
    { label: "notes", key: "notes" },
    { label: "stripeId", key: "stripeId" },
    { label: "isSnowbrandMember", key: "isSnowbrandMember" },
  ];
  

  return (
    <ListPageLayout>
      <TopNav title={intl.formatMessage(sectionNames.orders)}>
        {!!onSettingsOpen && (
          <CardMenu
            className={classes.settings}
            menuItems={[
              {
                label: intl.formatMessage({
                  id: "WbV1Xm",
                  defaultMessage: "Order Settings",
                  description: "button",
                }),
                onSelect: onSettingsOpen,
              },
              ...extensionMenuItems,
            ]}
          />
        )}
        <Button>
          <CSVLink
            data={getCSVData(listProps.orders)}
            headers={headers}
            target="_blank"
            filename={"OrderData"}
          >
            {"Export"}
          </CSVLink>
        </Button>
        <span>&nbsp;</span>
        <ButtonWithSelect
          disabled={limitsReached}
          options={extensionCreateButtonItems}
          data-test-id="create-order-button"
          onClick={onAdd}
        >
          <FormattedMessage
            id="LshEVn"
            defaultMessage="Create order"
            description="button"
          />
        </ButtonWithSelect>
        {hasLimits(limits, "orders") && (
          <LimitsInfo
            text={intl.formatMessage(
              {
                id: "zyceue",
                defaultMessage: "{count}/{max} orders",
                description: "placed order counter",
              },
              {
                count: limits.currentUsage.orders,
                max: limits.allowedUsage.orders,
              },
            )}
          />
        )}
      </TopNav>
      {limitsReached && <OrderLimitReached />}
      <Card>
        <FilterBar
          currentTab={currentTab}
          initialSearch={initialSearch}
          onAll={onAll}
          onFilterChange={onFilterChange}
          onSearchChange={onSearchChange}
          onTabChange={onTabChange}
          onTabDelete={onTabDelete}
          onTabSave={onTabSave}
          tabs={tabs}
          allTabLabel={intl.formatMessage({
            id: "WRkCFt",
            defaultMessage: "All Orders",
            description: "tab name",
          })}
          filterStructure={filterStructure}
          searchPlaceholder={intl.formatMessage({
            id: "wTHjt3",
            defaultMessage: "Search Orders...",
          })}
        />
        <OrderList {...listProps} />
      </Card>
    </ListPageLayout>
  );
};
OrderListPage.displayName = "OrderListPage";
export default OrderListPage;
